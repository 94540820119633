<template>
  <v-overlay class="master-overlay" :value="overlay">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>

<script>

export default {
  name: "LoadingMask",
  props: ["overlay"]
};
</script>
<style>
  .v-overlay--active.master-overlay {
    z-index: 999 !important;
  }
</style>