import ImportService from '@/services/import.js';
import ImportContactService from '@/services/import_contact.js'

// initial state
const state = () => ({
  imports: [],
  contacts: [],
  totalItems: 0
});

// getters
const getters = {
  getImports: (state) => {
    return state.imports;
  },

  getContacts: (state) => {
    return state.contacts;
  },

  getContactsTotalItems: (state) => {
    return state.totalItems
  }
}

// actions
const actions = {
  load({ commit }) {
    return new Promise((resolve, reject) => {
      try {
        ImportService.getAll().then((response) => {
          commit("setImports", response);

          resolve({ success: true, data: response });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  },

  get({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const { uuid, page, itemsPerPage, order } = payload;

        ImportService.get(uuid, page, itemsPerPage, order).then((response) => {
          commit("setContacts", {
            data: response.data
          });

          commit("setContactsTotalItems", response.totalItems);

          resolve({ success: true, data: response });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  },

  create({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const { name } = payload;
        ImportService.create(name).then((response) => {
          commit("addImport", response);

          resolve({ success: true, data: response });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  },

  feed({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const { uuid, data } = payload;
        ImportService.feed(uuid, data).then((response) => {
          commit("addImport", response);
          resolve({ success: true, data: response });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  },

  sendInvitation({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const { uuid } = payload;
        ImportContactService.send(uuid).then((response) => {
          commit('updateInvitationSentAt', response);
          resolve({ success: true, data: response });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  },

  sendAllInvitation({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      try {
        ImportContactService.sendAll(uuid).then((response) => {
          console.log(commit);
          resolve({ success: response });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  }
}

// mutations
const mutations = {
  setImports(state, imports) {
    state.imports = imports;
  },
  addImport(state, _import) {
    let exist = state.imports.find((el) => el.uuid == _import.uuid);

    exist ? exist = _import : state.imports.push(_import);
  },
  setContacts(state, payload) {
    const { data } = payload;

    state.contacts = data;
  },
  updateInvitationSentAt(state, contact) {
    let exist = state.contacts.find((el) => el.uuid == contact.uuid);
    
    if (exist) {
      exist.invitationSentAt = contact.invitationSentAt
      exist.eligibleToInvitation = contact.eligibleToInvitation
    }
    else {
      state.contacts.push(contact)
    }
  },
  setContactsTotalItems(state, totalItems) {
    state.totalItems = totalItems;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}