import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login/Login.vue'
import NotFound from '@/views/NotFound/NotFound.vue'
import Home from '@/views/Home/Home.vue'
import ImportList from '@/views/Imports/List.vue'
import Import from '@/views/Imports/Import.vue'
import ImportUser from '@/views/Import/User.vue'
import GroupsList from '@/views/Groups'
import Group from '@/views/Groups/Group'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: {
      guest: true,
      paidOnly: false
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      guest: false,
      paidOnly: false
    }
  },
  {
    path: '/imports',
    name: 'ImportList',
    component: ImportList,
    meta: {
      guest: false,
      paidOnly: true
    }
  },
  {
    path: '/imports/:uuid',
    name: 'Import',
    component: Import,
    meta: {
      guest: false,
      paidOnly: true
    }
  },
  {
    path: '/import/user',
    name: 'ImportUser',
    component: ImportUser,
    meta: {
      guest: false,
      paidOnly: true
    }
  },
  {
    path: '/groups',
    name: 'Groups',
    component: GroupsList,
    meta: {
      guest: false,
      paidOnly: true
    }
  },
  {
    path: '/group/:uuid',
    name: 'Group',
    component: Group,
    meta: {
      guest: false,
      paidOnly: true
    }
  },
  { 
    path: '/NotFound',
    name: 'NotFound',
    component: NotFound,
    meta: {
      guest: true,
      paidOnly: false
    }
  },
]

const router = new VueRouter({
  routes
})

// Route par défaut
const defaultRoute = "Home";

router.beforeEach((to, from, next) => {  
  const token = localStorage.getItem('access_token');
  
  // Si la route n'existe pas
  if (to.matched.length <= 0) {
    next({ name: 'NotFound'})
  }
  // Si la route a besoin d'authentification
  else if (to.matched.some(record => !record.meta.guest)) {
    // Si l'user n'est pas auth
    if (token == null) {
      next({ name: 'login'})
    }
    else {
      // Si la route a besoin d'un utilisateur payant
      if (to.matched.some(record => record.meta.paidOnly)) {
        const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
        if (user == null || !user.roles || !user.roles.includes("ROLE_MANAGER")) {
          next({ name: defaultRoute})
        }
        else {
          next()
        }
      }
      else {
        next();
      }
    }
  } else {
    // Si l'utilisateur n'est pas auth c'est parfait
    if(token == null) {
      next()
    }
    // Sinon il faut afficher la route par défaut
    else {
      // Si c'est la route 404
      if (to.name == "NotFound") {
        next();
      }
      else {
        next({ name: defaultRoute})
      }
    }
  }
});

export default router