export class ContactDTO {
  constructor(data) {
    this._id = data["@id"]
    this._type = data["@type"]
    this.uuid = data.uuid
    this.description = data.description
    this.firstname = data.firstname
    this.lastname = data.lastname
    this.nickname = data.nickname
  }
}