<template>
  <div class="mx-2 my-2">
    <div style="display: inline-flex" class="mb-2">
      <v-icon large class="mr-2">mdi-home</v-icon>
      <h1>Accueil</h1>
    </div>
    <v-card>
      <v-card-title>Bienvenue sur votre Manager Soolizi !</v-card-title>
      <v-card-text>
        Grâce à cette interface de gestion, vous pouvez :
        <ul>
          <li>
            Ajoutez des contacts de votre établissement avec qui vous pourrez échanger en les important directement depuis un fichier Excel (xlsx, csv) de votre ordinateur
            <ul>
              <li>
                Pour importer des contacts, assurez-vous d’avoir un fichier Excel avec les adresses mail des contacts que vous souhaitez ajouter. En cliquant sur le bouton « importer des contacts » un courriel sera envoyer à tous les contacts de votre part pour les inviter à rejoindre Soolizi.
              </li>
            </ul>
          </li>
        </ul>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>Modifier votre abonnement en fonction du nombre de contacts importés (voir grille d’abonnement ci-dessous)</v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12" md="1"></v-col>
            <v-col cols="12" md="3">
                <v-card
                  class="mx-auto"
                >
                  <v-img
                    class="ma-auto"
                    width="250"
                    src="@/assets/pricing/little.png"
                  ></v-img>

                  <v-card-title>Nombre d'utilisateurs</v-card-title>
                  <v-card-subtitle> &lt; 50 personnes</v-card-subtitle>

                  <v-divider class="mx-4"></v-divider>

                  <v-card-title>30 € / mois</v-card-title>

                  <v-card-actions>
                    <v-btn
                      color="primary"
                    >
                      Choisir cet abonnement
                    </v-btn>
                  </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card
                  class="mx-auto"
                >
                  <v-img
                    class="ma-auto"
                    width="250"
                    src="@/assets/pricing/medium.png"
                  ></v-img>

                  <v-card-title>Nombre d'utilisateurs</v-card-title>
                  <v-card-subtitle> &lt; 100 personnes</v-card-subtitle>

                  <v-divider class="mx-4"></v-divider>

                  <v-card-title>55 € / mois</v-card-title>

                  <v-card-actions>
                    <v-btn
                      color="primary"
                    >
                      Choisir cet abonnement
                    </v-btn>
                  </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card
                  class="mx-auto"
                >
                  <v-img
                    class="ma-auto"
                    width="250"
                    src="@/assets/pricing/big.png"
                  ></v-img>

                  <v-card-title>Nombre d'utilisateurs</v-card-title>
                  <v-card-subtitle>Illimités</v-card-subtitle>

                  <v-divider class="mx-4"></v-divider>

                  <v-card-title>75 € / mois</v-card-title>

                  <v-card-actions>
                    <v-btn
                      color="primary"
                    >
                      Choisir cet abonnement
                    </v-btn>
                  </v-card-actions>
                </v-card>
            </v-col>
          </v-row>
        </v-container>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'Home',

  components: {  },

  data: function() {
    return {
      items: [
      ],
    }
  },
  
  mounted: function() {
    
  },

  methods: {
    
  }
}
</script>
<style>

</style>