import http from "@/http-common";

const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

class AuthService {
  login(email, password) {
    const url = 'auth/token';

    return http.post(url, { username: email, password })
    .then((response) => {
      const { data } = response;
      
      if (data && data.token && data.refresh_token) {
        return {
          user: parseJwt(data.token),
          access_token: data.token,
          refresh_token: data.refresh_token
        }
      }
      else {
        return false;
      }
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }

  refresh(refresh_token) {
    const url = 'auth/token/refresh';

    return http.post(url, { refresh_token })
    .then((response) => {
      const { data } = response;
      
      if (data && data.token && data.refresh_token) {
        return {
          user: parseJwt(data.token),
          access_token: data.token,
          refresh_token: data.refresh_token
        }
      }
      else {
        return false;
      }
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }
}

export default new AuthService();