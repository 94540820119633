<template>
  <v-dialog v-model="show" persistent >
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>Créer un groupe</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          dark
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-4">Vous allez créer un groupe relié a votre compte Soolizi où les contacts seront automatiquement importés en fonction du groupement que vous allez mettre en place avec les champs ci-dessous.</v-card-text>
      <v-card-text>Pas d'inquiétude si jamais vous vous trompez. Un groupe Soolizi est visible uniquement pas vous. Vous pouvez donc supprimer un groupe et en recréer un a l'infini sans que personne ne soit au courant.</v-card-text>
      <div class="d-flex">
        <v-col cols="3"></v-col>
        <v-col cols="6">
          <v-select
            v-model="field"
            :items="fields"
            label="Choisir le champ de groupement"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="3"></v-col>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'GroupModal',

  components: {  },

  props: {
    show: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: String,
      default: null
    },
    fields: {
      type: Array,
      default: () => []
    },
  },

  computed: {
    ...mapGetters({
      getImports: '_import/getImports'
    })
  },

  data: function() {
    return {
      field: null
    }
  },
  
  mounted: function() {
    
  },

  methods: {
    close: function() {
      this.reset();
      this.$emit('close');
    },
    reset: function() {

    }
  }
}
</script>
<style>

</style>