<template>
  <v-dialog v-model="dialog" transition="dialog-top-transition">
    <v-card>
      <v-card-title class="text-h5">
        Êtes-vous sûr de vouloir continuer ?
      </v-card-title>

      <v-card-text>
        Si vous vous deconnectez, vous allez supprimer vos données en attente de téléchargement et elles ne seront plus récupérables.
        Vous êtes sur de vouloir continuer ?
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="green"
          text
          @click="dialog = false"
        >
          Non, j'annule !
        </v-btn>

        <v-btn
          color="red"
          text
          @click="logout"
        >
          Déconnexion
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { EventBus } from '@/event-bus';

export default {
  name: "Logout",

  computed: {
      ...mapGetters({
        
      })
  },

  data: () => ({
    dialog: false
  }),

  methods: {
    tryLogout: function() {
      this.dialog = true;
    },
    logout: function() {
      this.$store.dispatch('user/logout');
    }
  },

  mounted: function() {
    EventBus.$on('forceLogout', this.logout);
    EventBus.$on('logout', this.tryLogout);
  }
};
</script>