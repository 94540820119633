import GenderService from '@/services/gender.js';

// initial state
const state = () => ({
  genders: []
});

// getters
const getters = {
  getGenders: (state) => {
    return state.genders;
  }
}

// actions
const actions = {
  load({ commit }) {
    return new Promise((resolve, reject) => {
      try {
        GenderService.get().then((response) => {
          commit("setGenders", response);

          resolve({ success: true, data: response });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  }
}

// mutations
const mutations = {
  setGenders(state, genders) {
    state.genders = genders;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}