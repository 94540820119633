<template>
  <div class="px-4 py-4">
    <p class="mb-0">Veuillez importer votre fichier de données utilisateurs.</p>
    <i>Nous acceptons seulement les fichiers csv et xlsx</i>
    <v-file-input
      class="mt-4"
      show-size
      accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      placeholder="Choisir un fichier csv/xlsx"
      prepend-icon="mdi-file"
      label="Choisir un fichier csv/xlsx"
      @change="onFileChange"
    ></v-file-input>
    <v-text-field
      v-model="headingRow"
      label="Ligne d'entête"
      placeholder="Ligne d'entête"
      outlined
      dense
      type="number"
      :min="1"
      @keyup="onHeadingRowChange"
    ></v-text-field>
  </div>
</template>

<script>
import { debounce } from "debounce";

export default {
  name: 'ImportFile',

  components: {  },

  data: function() {
    return {
      headingRow: 1
    }
  },

  methods: {
    onFileChange: function(file) {
      this.$emit('fileChange', file);
    },
    onHeadingRowChange: debounce(function() {
      this.$emit('headingRowChange', this.headingRow);
    }, 500)
  }
}
</script>
<style>

</style>