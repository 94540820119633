<template>
  <div class="h-100 w-100 center-xy">
    <v-card class="px-4 py-4">
      <h1>Cette page n'existe pas !</h1>
      <v-btn color="primary" block class="mt-4" @click="$router.push('home')">Revenir en lieu sûr</v-btn>
    </v-card>
  </div>
</template>

<script>
//import i18n from '@/plugins/i18n';

export default {
  name: 'NotFound',
  components: { },
  methods: {

  }
}
</script>