import http from "@/http-common";

import { GroupDTO } from "../class/group";
import { ContactDTO } from "../class/contact";

class GroupService {
  getAll(page = 1, itemsPerPage = 10) {
    const url = `/user-groups?page=${page}&itemsPerPage=${itemsPerPage}`;

    return http.get(url)
    .then((response) => {
      const { data } = response;
      
      if (data) {
        const imports = [];
        data["hydra:member"].forEach(member => {
          imports.push(new GroupDTO(member))
        });

        return imports;
      }
      else {
        return false;
      }
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }

  get(uuid) {
    const url = `/user-groups/${uuid}`;

    return http.get(url)
    .then((response) => {
      const { data } = response;
      
      if (data) {
        return new GroupDTO(data)
      }
      else {
        return false;
      }
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }
  
  getContact(uuid, page = 1, itemsPerPage = 10) {
    const URLParams = new URLSearchParams();
    URLParams.set("page", page);
    URLParams.set("itemsPerPage", itemsPerPage);

    const url = `/user-groups/${uuid}/relationships?${URLParams.toString()}`;

    return http.get(url)
    .then(async (response) => {
      const { data } = response;
      
      if (data) {
        const contacts = [];
        
        await data["hydra:member"].forEach(async member => {
          const user_url = member.user;
          const _ = await http.get(user_url);
          contacts.push({...new ContactDTO(_.data), relationship_uuid: member.uuid})
        });

        return {
          data: contacts,
          totalItems: data["hydra:totalItems"]
        };
      }
      else {
        return false;
      }
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }

  getMyFriend(page = 1, itemsPerPage = 10) {
    const URLParams = new URLSearchParams();
    URLParams.set("page", page);
    URLParams.set("itemsPerPage", itemsPerPage);

    const url = `/relationships?${URLParams.toString()}`;

    return http.get(url)
    .then(async (response) => {
      const { data } = response;
      
      if (data) {
        const contacts = [];

        await data["hydra:member"].forEach(async member => {
          const user_url = member.account;
          const _ = await http.get(user_url);
          contacts.push(new ContactDTO(_.data))
        });

        return {
          data: contacts,
          totalItems: data["hydra:totalItems"]
        };
      }
      else {
        return false;
      }
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }

  create(name) {
    const url = 'user-groups';

    return http.post(url, { name })
    .then((response) => {
      const { data } = response;
      
      if (data) {
        return new GroupDTO(data);
      }
      else {
        return false;
      }
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }

  delete(uuid) {
    const url = 'user-groups/' + uuid;

    return http.delete(url)
    .then(() => {
      return true;
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }

  addUser(groupUUID, userUUID) {
    const url = `/user-groups/${groupUUID}/relationships/add`;

    return http.post(url, {
      user_uuid: userUUID
    })
    .then((response) => {
      const { data } = response;
      
      if (data) {
        return true;
      }
      else {
        return false;
      }
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }

  removeUser(relationshipsUUID) {
    const url = `/user-group-relationships/${relationshipsUUID}`;

    return http.delete(url, {
      uuid: relationshipsUUID
    })
    .then((response) => {
      const { data } = response;
      
      if (data) {
        return true;
      }
      else {
        return false;
      }
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }
}

export default new GroupService();