/* eslint-disable */
import Vue from 'vue'

// initial state
const state = () => ({
  mask: false,
  menu: false,
  error: false
});

// getters
const getters = {
  getMask: (state) => {
    return state.mask;
  },
  getError: (state) => {
    return state.error;
  },
  getMenu: (state) => {
    return state.menu;
  }
}

// actions
const actions = {
  
}

// mutations
const mutations = {
  mask(state) {
    state.mask = true;
  },
  unmask(state) {
    state.mask = false;
  },
  setError(state, error) {
    Vue.notify({
      group: 'error',
      type: 'error',
      title: 'Il y a eu une erreur',
      text: 'Si le problème persiste, veuillez nous contacter.'
    });

    state.error = false;
  },
  setMenu(state, menu) {
    state.menu = menu;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}