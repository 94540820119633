<template>
  <div class="px-4 py-4">
    <p>Veuillez configurer votre fichier de données utilisateurs.</p>
    <v-data-table
      :headers="headingRow"
      :items="data"
      hide-default-footer
      disable-filtering
      disable-pagination
      disable-sort
      class="mt-4 elevation-10"
    >
      <!-- On rajoute une ligne avec des selectfields pour pouvoir faire les liens -->
      <template v-slot:body.prepend>
        <tr>
          <td v-for="header in headingRow" :key="header.text">
            <v-select
              v-model="select[header.value]"
              clearable
              :items="fields"
              item-text="text"
              item-value="value"
              label="Associer un champs"
              @change="onSelectChange"
            ></v-select>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'ConfigurationFile',

  components: {  },

  props: {
    fields: {
      type: Array,
      default: () => []
    },
    headingRow: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  data: function() {
    return {
      select: {}
    }
  },

  methods: {
    onSelectChange: function() {
      this.$emit('change', this.select);
    }
  }
}
</script>
<style>

</style>