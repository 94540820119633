/**
 * Fichier principal qui permet de setup l'application et les différentes lib
 * C'est le premier fichier que VUE ouvre
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/**
 * Ici j'importe des plugins et pas directement la lib,
 * ça me permet de la configurer dans un fichier distinct
 */
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'

// Machine à états/store
import Vuex from 'vuex'

// Lib HTTP
import Axios from 'axios'

// Permet de jolie transition et très leger
import VuePageTransition from 'vue-page-transition'

// Notificon local sous forme de toast
import Notifications from 'vue-notification'

import env from './config/env';

/**
 * Permet de faire this.$http a tout moment plutôt que d'import axios partout  
 * En général, il ne devrait pas être utilisé car nous utiliserons plutôt le service http-common
 */
Vue.prototype.$http = Axios;

Vue.config.productionTip = false;

Vue.use(Vuex);

Vue.use(VuePageTransition);
Vue.use(Notifications);

Vue.use(env);

// Création de l'application avec ses plugins
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');
