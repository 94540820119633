<template>
  <div>
    <h1>{{ title }}</h1>
      <v-container fluid>
        <v-row align="center" v-for="data in getData" :key="getData.indexOf(data)">
          <v-col cols="3">
            <v-subheader>
              {{ data }}
            </v-subheader>
          </v-col>
          <v-col cols="9">
            <v-select
              v-model="select[getData.indexOf(data)]"
              :items="field.data"
              :label="title"
              item-text="name"
              item-value="uuid"
              hide-details
              single-line
              @change="onSelectChange"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
  </div>
</template>

<script>
export default {
  name: 'ConfigurationSecondaryData',

  components: {  },
  
  computed: {
    getData() {
      // Recherche de l'index dans la configuration
      const index = Object.keys(this.configurationData)[Object.values(this.configurationData).indexOf(this.field.value)];

      // Recherche de chaque index pour chaque ligne en gardant l'unicité
      const uData = [];

      if (this.data) {
        for (let i = 0; i < this.data.length; i++) {
          const element = this.data[i][index];
          
          if (!uData.includes(element)) {
            uData.push(element);
          }
        }
      }
      
      return uData;
    }
  },

  props: {
    title: {
      type: String,
      default: ""
    },
    field: {
      type: Object,
      default: () => {}
    },
    configurationData: {
      type: Object,
      default: () => {}
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  data: function() {
    return {
      select: {}
    }
  },

  methods: {
    onSelectChange: function() {
      this.$emit('change', [
        { value: this.getData[0], custom: this.select[0] },
        { value: this.getData[1], custom: this.select[1] },
        { value: this.getData[2], custom: this.select[2] }
      ]);
    }
  }
}
</script>
<style>

</style>