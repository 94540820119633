import http from "@/http-common";

import { ImportContactDTO } from "../class/import_contact";

class ImportContactService {
  send(uuid) {
    const url = `/manager/contacts/${uuid}/invitation/send`;

    return http.post(url, {})
    .then((response) => {
      const { data } = response;
      
      if (data) {
        return new ImportContactDTO(data);
      }
      else {
        return false;
      }
    })
    .catch((e) => {
      console.error(e);
      throw e;  
    });
  }

  sendAll(uuid) {
    const url = `/manager/imports/${uuid}/contacts/invitations/send`;

    return http.post(url, [])
    .then((response) => {
      const { data } = response;
      
      if (data) {
        return true;
      }
      else {
        return false;
      }
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }
}

export default new ImportContactService();