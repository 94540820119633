<template>
  <div class="mx-2 my-2">
    <div style="display: inline-flex" class="mb-2">
      <v-icon large class="mr-2">mdi-account-group-outline</v-icon>
      <h1>Vos groupes</h1>
    </div>
    <v-card class="mb-12">
      <div class="px-4 py-4">
        <v-btn color="green" @click="dialog = true">Créer un groupe</v-btn>
        <v-data-table
          :headers="headers"
          :items="groups"
          :loading="loading"
          hide-default-footer
          disable-filtering
          disable-pagination
          disable-sort
          class="mt-4 elevation-10"
        >
          <template v-slot:item.createdAt="{ item }">
            {{ new Date(item.createdAt).toLocaleString() }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              class="mx-2"
              fab
              dark
              x-small
              color="primary"
              link :to="'/group/' + item.uuid"
            >
              <v-icon dark>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>        
    </v-card>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Créer un groupe</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="name"
                  label="Nom du groupe"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Annuler
          </v-btn>
          <v-btn
            color="blue darken-1"
            @click="createGroup"
          >
            Créer le groupe
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import GroupService from '@/services/group.js';

export default {
  name: 'GroupsList',

  components: {  },

  computed: {
    
  },

  data: function() {
    return {
      loading: true,
      dialog: false,
      name: null,
      headers: [
        { text: '', value: 'actions', sortable: false },
        { text: 'Nom', align: 'start', value: 'name', },
        { text: 'Nombre d\'utilisateur', align: 'start', value: 'totalUsers', },
        { text: 'Date de création', value: 'createdAt' },
      ],
      groups: []
    }
  },
  
  mounted: function() {
    GroupService.getAll().then((response) => {
      this.groups = response;
      this.loading = false;
    })
  },

  methods: {
    createGroup: function() {
      GroupService.create(this.name).then(() => {
        this.dialog = false;

        GroupService.getAll().then((response) => {
          this.groups = response;
          this.loading = false;
        })
      })
      this.dialog = false;
    }
  }
}
</script>
<style>

</style>