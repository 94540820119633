<template>
  <div class="mx-2 my-2">
    <div style="display: inline-flex" class="mb-2">
      <v-icon large class="mr-2">mdi-table-arrow-down</v-icon>
      <h1>Liste des imports</h1>
    </div>
    <v-card class="mb-12">
      <div class="px-4 py-4">
        <v-data-table
          :headers="headers"
          :items="getImports"
          :loading="loading"
          hide-default-footer
          disable-filtering
          disable-pagination
          disable-sort
          class="mt-4 elevation-10"
        >
          <template v-slot:item.createdAt="{ item }">
            {{ new Date(item.createdAt).toLocaleString() }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              class="mx-2"
              fab
              dark
              x-small
              color="primary"
              link :to="'/imports/' + item.uuid"
            >
              <v-icon dark>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>        
    </v-card>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'ImportList',

  components: {  },

  computed: {
    ...mapGetters({
      getImports: '_import/getImports'
    })
  },

  data: function() {
    return {
      loading: true,
      headers: [
        { text: '', value: 'actions', sortable: false },
        { text: 'Nom', align: 'start', value: 'name', },
        { text: 'Date de création', value: 'createdAt' },
      ],
    }
  },
  
  mounted: function() {
    this.$store.dispatch('_import/load').finally(() => {
      this.loading = false;
    })
  },

  methods: {
  }
}
</script>
<style>

</style>