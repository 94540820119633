<template>
  <div class="px-4 py-4">
    <p>Voici vos données, sont elles corrects ?</p>
    <div class="mb-4">
      <v-alert
        v-for="error in errors"
        :key="JSON.stringify(error.el)"
        dense
        outlined
        type="error"
      >
        La ligne {{ error.el.lastname }} {{ error.el.firstname }} est ignorée car l'email <i>{{ error.el.email }}</i> existe déjà dans le même import
      </v-alert>
    </div>
    <v-data-table
      :headers="header"
      :items="testedData"
      hide-default-footer
      disable-filtering
      disable-pagination
      disable-sort
      class="mt-4 elevation-10"
    >
      <template v-slot:item.gender_uuid="{ item }">
       {{ item.gender_uuid ? getGenders.find((el) => el.uuid == item.gender_uuid).name : item.gender_uuid }}
      </template>
      <template v-slot:item.extra_data="{ item }">
       {{ JSON.stringify(item.extra_data) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'TestData',

  components: {  },

  props: {
    header: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
    testData: {
      type: String,
      default: ""
    }
  },

  computed: {
    ...mapGetters({
      getGenders: 'gender/getGenders'
    })
  },

  watch: {
    testData: function() {
      this.testedData = [];

      // Pour chaque ligne il faut faire des vérifications
      this.data.forEach(element => {
        // Unicité de l'e-mail
        if (this.testedData.filter(el => el.email == element.email).length >= 1) {
          this.errors.push({
            type: 0,
            el: element
          });
        }
        else {
          this.testedData.push(element);
        }
      });

      this.$emit("testedDataChange", this.testedData);
    }
  },

  data: function() {
    return {
      testedData: [],
      errors: []
    }
  },

  methods: {
    
  }
}
</script>
<style>

</style>