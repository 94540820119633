<template>
  <div class="mx-2 my-2">
    <div style="display: inline-flex" class="mb-2">
      <v-icon large class="mr-2">mdi-microsoft-excel</v-icon>
      <h1>Importez des utilisateurs</h1>
    </div>
    
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step :complete="step > 0" step="1">Téléchargement</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 1" step="2">Liaisons</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 2" step="3">Configuration</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 3" step="4">Test</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 4" step="5">Importation</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-btn :disabled="file == null" color="primary" @click="extractFile">Etape suivante</v-btn>

          <v-card class="mb-12 mt-4" :disabled="loading">
            <ImportFile @fileChange="onFileChange" @headingRowChange="onHeadingRowChange"/>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-btn :disabled="!configurationValid" color="primary" @click="setSecondaryData">Etape suivante</v-btn>
          <v-btn text @click="step = 1">Etape précédente</v-btn>

          <v-card class="mb-12 mt-4">
            <ConfigurationFile  :fields="fields" :headingRow="headingRowData" :data="configurationSample" @change="onConfigurationChange"/>
            <ConfigurationFileError :valid="configurationValid" :errors="configurationError" />
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-btn color="primary" @click="generateData">Etape suivante</v-btn>
          <v-btn text @click="step = 2">Etape précédente</v-btn>

          <v-card class="mb-12 mt-4">
            <div class="px-4 py-4">
              <p>Nous devons faire le lien entre vos données secondaires et nos données secondaires.</p>
              <p>Par exemple, si chez nous le genre "Homme" est nommé "Homme" et que chez vous il est nommé "H", nous devons pouvoir faire le lien</p>
              <ConfigurationSecondaryData title="Genre" :field="fields[3]" :configurationData="configurationData" :data="fileData" @change="onGenderDataChange"/>
            </div>        
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-btn color="primary" @click="createImport">Etape suivante</v-btn>
          <v-btn text @click="step = 3">Etape précédente</v-btn>

          <v-card class="mb-12 mt-4">
            <TestData :header="fields" :data="data" :testData="testData" @testedDataChange="testedDataChange" />
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="5">
          <v-card class="px-4 py-4 mb-12">
            Import terminé, vous pouvez maintenant suivre vos utilisateurs dans le menu "Imports"
          </v-card>
          <v-btn text @click="reload">Recommencer</v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import ImportFile from './components/user/ImportFile.vue';

import ConfigurationFile from './components/user/ConfigurationFile.vue';
import ConfigurationFileError from './components/user/ConfigurationFileError.vue';

import ConfigurationSecondaryData from './components/user/ConfigurationSecondaryData.vue';

import TestData from './components/user/TestData.vue';

import readXlsxFile from 'read-excel-file'

import { mapGetters } from 'vuex'

export default {
  name: 'ImportUser',

  components: { ImportFile, ConfigurationFile, ConfigurationFileError, ConfigurationSecondaryData, TestData },

  computed: {
    ...mapGetters({
      getGenders: 'gender/getGenders'
    })
  },

  data: function() {
    return {
      step: 1,
      loading: false,
      fields: [
        {
          value: 'firstname',
          text: 'Prénom',
          type: 'string',
          required: true
        },
        {
          value: 'lastname',
          text: 'Nom',
          type: 'string',
          required: true
        },
        {
          value: 'given_name',
          text: 'Autres nom/prénom',
          type: 'string'
        },
        {
          value: 'gender_uuid',
          text: 'Genre',
          type: 'integer',
          required: false,
          data: []
        },
        {
          value: 'phone',
          text: 'Numéro de téléphone',
          type: 'string',
          required: false
        },
        {
          value: 'email',
          text: 'E-mail',
          type: 'string',
          required: true
        },
        {
          value: 'extra_data',
          text: 'Autre',
          type: 'json',
          required: false,
          multiple: true
        }
      ],
      file: null,
      headingRow: 0,
      headingRowData: [],
      fileData: null,
      configurationSample: [],
      configurationData: {},
      configurationValid: false,
      configurationError: [],
      genderData: {},
      testData: "",
      testedData: [],
      data: [],
      dbImport: null
    }
  },
  
  mounted: function() {

  },

  methods: {
    onFileChange: function(file) {
      this.file = file;
    },
    onHeadingRowChange: function(headingRow) {
      this.headingRow = headingRow - 1;
    },
    extractFile: function() {
      this.loading = true;
      this.$store.commit('app/mask');

      readXlsxFile(this.file).then((rows) => {
        this.fileData = rows;
        this.loading = false;
        this.$store.commit('app/unmask');
        this.loadConfigurationPanel();
      })
    },
    loadConfigurationPanel: function() {
      this.step = 2;
      this.headingRowData = [];
      this.configurationSample = [];

      // Création du header pour le tableau de configuration avec chaque cell de la ligne de header
      for (let index = 0; index < this.fileData[this.headingRow].length; index++) {
        const line = this.fileData[this.headingRow][index];
        
        this.headingRowData.push({
          text: line,
          value: index.toString()
        });
      }

      // Pour chaque ligne a partir de la ligne header
      for (let i = (1 + this.headingRow); i < (6 + this.headingRow); i++) {
        const line = this.fileData[i];

        const data = {};
        // Pour chaque cell
        for (let j = 0; j < line.length; j++) {
          const cell = line[j];
          data[j.toString()] = cell;
        }
        
        this.configurationSample.push(data);
      }
    },
    onConfigurationChange: function(configurationData) {
      this.configurationData = configurationData;
      this.checkConfigurationConstrainte();
    },
    checkConfigurationConstrainte: function() {
      this.configurationError = [];
      this.configurationValid = true;

      // Pour chaque champs, il faut vérifier qu'une configuration existe
      for (let index = 0; index < this.fields.length; index++) {
        const field = this.fields[index];
        let valid = false;

        if (field.required) {
          // Donc recherche une configuration
          if (Object.values(this.configurationData).includes(field.value)) {
            valid = true;
          }

          // Vérification qu'il est bien séléctionné
          if (!valid) {
            this.configurationValid = false;
            this.configurationError.push({
              name: field.text,
              type: "required",
              data: null
            });
          }
        }
      }
    },
    setSecondaryData: function() {
      this.fields[3].data = this.getGenders; 
      this.step = 3;
    },
    onGenderDataChange: function(genderData) {
      this.genderData = genderData;
    },
    generateData: function() {
      this.data = [];

      for (let index = this.headingRow + 1; index < this.fileData.length; index++) {
        const row = this.fileData[index];
        
        // L'object qui va contenir les données d'une ligne pour l'API
        const rowData = {};

        // Pour chaque cell de la ligne, il faut faire l'association
        for (let j = 0; j < row.length; j++) {
          const cell = row[j];
          const value = this.configurationData[j];

          const field = this.fields.find(el => el.value == value);

          // Si il y a un champs associé
          if (field) {
            // Si c'est un multidata
            if (field.data) {
              // TODO Pour l'instant seulement genre
              const gender = this.genderData.find(el => el.value == cell);

              if (gender) {
                rowData[field.value] = gender.custom;
              }
              else {
                rowData[field.value] = this.getGenders[2].uuid;
              }
            }
            else if (field.multiple) {
              if (typeof rowData[field.value] != "object") {
                rowData[field.value] = {};
              }

              rowData[field.value][this.headingRowData[j].text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll(" ", "_").replaceAll(".", "_").toLowerCase()] = cell;
            }
            else {
              rowData[field.value] = cell;
            }
          }
        }

        if (rowData) {
          this.data.push(rowData);
        }
      }

      this.testData = new Date().getTime().toString();      
      this.step = 4;
    },

    testedDataChange: function(testedData) {
      this.testedData = testedData;
    },

    createImport: function() {
      this.$store.commit('app/mask');

      this.$store.dispatch('_import/create', {
        name: "import_" + new Date().toLocaleDateString().replaceAll('/', '_') + "_" + new Date().getTime().toString()
      }).then((response) => {
        if (response.success) {
          this.dbImport = response.data;

          this.feedImport();
        }
      }).catch(() => {
        // Lève le mask
        this.$store.commit('app/unmask');
      });
    },

    feedImport: function() {
      this.$store.commit('app/mask');

      this.$store.dispatch('_import/feed', {
        uuid: this.dbImport.uuid,
        data: this.testedData
      }).then((response) => {
        if (response.success) {
          this.step = 5;
        }
      }).catch(() => {
        
      })
      .finally(() => {
        // Lève le mask
        this.$store.commit('app/unmask');
      })
    },

    reload: function() {
      window.location.reload();
    }
  }
}
</script>
<style>

</style>