export class ImportContactDTO {
  constructor(data) {
    this._id = data["@id"]
    this._type = data["@type"]
    this.uuid = data.uuid
    this.firstname = data.firstname
    this.lastname = data.lastname
    this.email = data.email
    this.phone = data.phone
    this.accountLinked = data.accountLinked
    this.eligibleToInvitation = data.eligibleToInvitation
    this.hasRelationship = data.hasRelationship
    this.invitationSentAt = data.invitationSentAt ? new Date(data.invitationSentAt) : null
    this.extraData = data.extraData
  }
}