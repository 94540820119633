export class ImportDTO {
  constructor(data) {
    this._id = data["@id"]
    this._type = data["@type"]
    this.uuid = data.uuid
    this.name = data.name

    this.createdAt = new Date(data.createdAt)
    this.updatedAt = new Date(data.updatedAt)
  }
}