import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import fr from 'vuetify/es5/locale/fr'

Vue.use(Vuetify);

// Erreur de ma part pour l'instant, il va falloir modifier la langue des tables en fonctions du choix de l'utilisateur
Vue.component('v-data-table', {
  methods: {
    changeLocale () {
      this.$vuetify.lang.current = 'fr'
    },
  },
})

export default new Vuetify({
    lang: {
      locales: { fr },
      current: 'fr',
    },
    theme: {
      options: {
        customProperties: true
      },
      dark: true,
      themes: {
        light: {
          primary: '#222222',
          secondary: '#A6A8A9',
          blue: '#1d2a31',
          red: '#ae001f',
          yellow: '#ffff1f',
          grey: '#adc9c9',
          accent: '#ffcd00',
          error: '#b71c1c'
        }
      }
    },
});
