import http from "@/http-common";

import { GenderDTO } from "../class/gender";

class GenderService {
  get() {
    const url = 'genders';

    return http.get(url)
    .then((response) => {
      const { data } = response;
      
      if (data) {
        const genders = [];
        data["hydra:member"].forEach(member => {
          genders.push(new GenderDTO(member))
        });

        return genders;
      }
      else {
        return false;
      }
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }
}

export default new GenderService();