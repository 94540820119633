<template>
  <div class="px-4 py-4">
    <v-alert
      v-for="error in errors"
      :key="error.name"
      dense
      outlined
      type="error"
    >
      Vous devez associer {{ error.name }} car c'est un champs obligatoire
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'ConfigurationFileError',

  components: {  },

  props: {
    valid: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style>

</style>