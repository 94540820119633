<template>
  <div class="mx-2 my-2">
    <div style="display: inline-flex" class="mb-2">
      <v-icon large class="mr-2">mdi-account-group-outline</v-icon>
      <h1>{{ group ? group.name : '' }}</h1>
    </div>
    <v-card class="mb-12">
      <div class="px-4 py-4">
        <v-btn color="success" @click="dialog = true" class="mr-4">Ajouter des utilisateurs</v-btn>
        <v-btn color="error" @click="deleteDialog = true">Supprimer le groupe</v-btn>
        <v-data-table
          :headers="headers"
          :items="users"
          :loading="loading"
          hide-default-footer
          disable-filtering
          disable-pagination
          disable-sort
          class="mt-4 elevation-10"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn
              class="mx-2"
              fab
              dark
              x-small
              color="red"
              @click="deleteUser(item)"
            >
              <v-icon dark>
                mdi-trash-can-outline
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>        
    </v-card>
    <v-dialog
      v-model="deleteDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Supprimer le groupe {{ group ? group.name : '' }}</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteDialog = false"
          >
            Annuler
          </v-btn>
          <v-btn
            color="red darken-1"
            @click="deleteGroup"
          >
            Supprimer le groupe
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Ajouter des utilisateurs dans {{ group ? group.name : '' }}</span>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="friends"
          :loading="loading"
          hide-default-footer
          disable-filtering
          disable-sort
          
        >
          <template v-slot:item.actions="{ item }">
            <v-btn
              class="mx-2"
              fab
              dark
              x-small
              color="green"
              :disabled="isDisabled(item.uuid) || isLoading(item.uuid)"
              :loading="isLoading(item.uuid)"
              @click="addUser(item)"
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import GroupService from '@/services/group.js';

export default {
  name: 'Group',

  components: {  },

  computed: {
    isDisabled() {
      return (uuid) => {
        return this.users.find(el => el.uuid == uuid) ? true : false;
      }
    },
    isLoading() {
      return (uuid) => {
        return this.loadingFriends.includes(uuid);
      } 
    }
  },

  data: function() {
    return {
      loading: true,
      dialog: false,
      deleteDialog: false,
      name: null,
      headers: [
        { text: '', value: 'actions', sortable: false },
        { text: 'Nom', align: 'start', value: 'lastname', },
        { text: 'Prénom', align: 'start', value: 'firstname', },
        { text: 'Pseudo', align: 'start', value: 'nickname', },
        { text: 'Description', align: 'start', value: 'description', },
      ],
      group: null,
      users: [],
      friends: [],
      loadingFriends: []
    }
  },
  
  mounted: function() {
    GroupService.get(this.$route.params.uuid).then((response) => {
      this.group = response;

      GroupService.getContact(this.$route.params.uuid).then((response) => {
        this.users = response.data;
      })

      GroupService.getMyFriend().then((response) => {
        this.friends = response.data;
      })

      this.loading = false;
    })
  },

  methods: {
    deleteGroup: function() {
      GroupService.delete(this.$route.params.uuid).then(() => {
        this.loading = false;
        this.$router.push('/groups');
      })
    },
    addUser: function(user) {
      this.loadingFriends.push(user.uuid);

      GroupService.addUser(this.$route.params.uuid, user.uuid).then(() => {
        this.loadingFriends = this.loadingFriends.filter(el => el != user.uuid);
        this.users.push(user);
      }).catch(() => {
        this.loadingFriends = this.loadingFriends.filter(el => el != user.uuid);
      })
    },
    deleteUser: function(user) {
      GroupService.removeUser(user.relationship_uuid).then(() => {
        GroupService.getContact(this.$route.params.uuid).then((response) => {
          this.users = response.data;
        })
      })
    },
  }
}
</script>
<style>

</style>