<template>
  <v-app id="inspire">
    <Uploader />
    <Navigation v-if="!isLogin && isLogged && !is404 "></Navigation>
    
    <v-main v-if="isReady">
      <Background v-if="isLogin || is404" />
      <vue-page-transition name="fade" class="h-100 center-y-parent">
        <router-view></router-view>
      </vue-page-transition>
    </v-main>

    <LoadingMask :overlay="getMask" />
    <ErrorPopup :overlay="getError" />

    <notifications position="top center" group="app" />

    <Logout />
  </v-app>
</template>

<script>
import LoadingMask from '@/components/App/Mask.vue'
import ErrorPopup from '@/components/App/Error_Popup.vue'
import Background from '@/components/App/Background.vue'
import Uploader from "@/components/Uploader/Uploader.vue";
import Logout from '@/components/App/Logout.vue'
import Navigation from '@/components/App/Navigation.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'App',

  components: { LoadingMask, ErrorPopup, Background, Logout, Navigation, Uploader },

  computed: {
    ...mapGetters({
      getAccessToken: 'user/getAccessToken',
      getMask: 'app/getMask',
      getError: 'app/getError',
      getMenu: 'app/getMenu'
    }),
    isLogin() {
      return this.$route.name == 'login' && this.getAccessToken == null;
    },
    is404() {
      return this.$route.name == 'NotFound';
    },
    isLogged() {
      return this.getAccessToken ? true : false;
    }
  },
  methods: {
    ready: function() {
      this.isReady = true;

      this.$store.dispatch('gender/load');
    }
  },
  data: function() {
    return {
      isReady: false
    }
  },

  mounted: function() {
    // Si ce n'est pas la route login
    if (this.$route.name != "login") {
      // Si pas de data de connexion, logout
      if (this.getAccessToken == null) {
        this.$store.dispatch('user/logout');
        return;
      }
      
      // Refresh du token
      this.$store.dispatch('user/refresh').then((response) => {
        if (response.success) {
          this.ready();
        }
        else {
          this.$store.dispatch('user/logout');
        }
      }).catch(() => {
        this.$store.dispatch('user/logout');
      })
    }
    else {
      this.ready();
    }
  }
};
</script>
<style>
  html {
    background-color: #eeeeee !important;
  }

  .center-x {    
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .center-y {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .center-y-parent {
    position: relative;
  }

  .center-y-child {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .center-xy {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .w-100 {
    width: 100%;
  }

  .h-100 {
    height: 100%;
  }
</style>
