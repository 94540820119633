import http from "@/http-common";

import { ImportDTO } from "../class/import";
import { ImportContactDTO } from "../class/import_contact";

class ImportService {
  getAll() {
    const url = 'manager/imports';

    return http.get(url)
    .then((response) => {
      const { data } = response;
      
      if (data) {
        const imports = [];
        data["hydra:member"].forEach(member => {
          imports.push(new ImportDTO(member))
        });

        return imports;
      }
      else {
        return false;
      }
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }

  get(uuid, page = 1, itemsPerPage = 10, order = {}) {
    const URLParams = new URLSearchParams();
    URLParams.set("page", page);
    URLParams.set("itemsPerPage", itemsPerPage);
    URLParams.set("order[invitationSentAt]", order.invitationSentAt);
    URLParams.set("order[createdAt]", order.createdAt);
    URLParams.set("order[updatedAt]", order.updatedAt);

    const url = `/manager/imports/${uuid}/contacts?${URLParams.toString()}`;

    return http.get(url)
    .then((response) => {
      const { data } = response;
      
      if (data) {
        const contacts = [];

        data["hydra:member"].forEach(member => {
          contacts.push(new ImportContactDTO(member))
        });

        return {
          data: contacts,
          totalItems: data["hydra:totalItems"]
        };
      }
      else {
        return false;
      }
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }

  create(name) {
    const url = 'manager/imports';

    return http.post(url, { name })
    .then((response) => {
      const { data } = response;
      
      if (data) {
        return new ImportDTO(data);
      }
      else {
        return false;
      }
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }

  feed(uuid, data) {
    const url = 'manager/imports/' + uuid + "/contacts/import";

    return http.post(url, data)
    .then((response) => {
      const { data } = response;
      
      if (data) {
        return new ImportDTO(data);
      }
      else {
        return false;
      }
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }
}

export default new ImportService();